import React, { useState } from "react"
import { OffersPageType } from "../../types/OffersPageType"
import type { UserType, AllLayoutsType } from "@lesmills/gatsby-theme-common"
import isEqual from "lodash/isEqual"

import { handleSaveProduct } from "@lesmills/gatsby-theme-common"

import {
  Hero,
  Varieties,
  TrainYourWay,
  Footer,
} from "@lesmills/gatsby-theme-sales-landing"

import Redeem from "../Redeem"

type Props = {
  data: OffersPageType,
  lang: string,
  user: UserType,
  layout?: AllLayoutsType,
  personalizedOfferPage?: boolean,
}

const Offers = ({
  data,
  lang = "",
  user = {},
  layout = {},
  personalizedOfferPage = false,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [affiliateLogo, setAffiliateLogo] = useState(null)

  const {
    hero_title = {},
    personalized_logo = {
      url: "",
      alt: "",
    },
    hero_photo = {
      url: "",
      alt: "",
      thumbnails: {
        mobile: {
          url: "",
          alt: "",
        },
      },
    },
    hero_video = {},
    hero_highlight = {},
    note_text = {},
    body = [],
    body1 = [],
    copyright = {},
  } = data || {}
  const { les_mills_plus_logo = {} } = layout || {}
  const [varieties = {}, app_stores_available = {}] = body
  const [footer_navigations, footer_socials] = body1
  const footer = {
    navigation: footer_navigations,
    social: footer_socials,
  }

  const handleAffiliateOffer = affiliateLogo => {
    setAffiliateLogo(affiliateLogo)
  }

  // Push data layer event when payload and when start now
  // JIRA ticket: https://lesmillsinternational.atlassian.net/browse/LA-952

  return (
    <>
      <Hero
        title={hero_title.text}
        heroImg={hero_photo}
        heroVideo={hero_video}
        personalized_logo={personalized_logo}
        heroMobileImg={hero_photo.thumbnails && hero_photo.thumbnails.mobile}
        information={hero_highlight.text}
        lang={lang}
        noteText={note_text}
        offersPage
        personalizedOfferPage={personalizedOfferPage}
        user={user}
        affiliateLogo={affiliateLogo}
        lesmillsLogo={les_mills_plus_logo}
      />
      <Redeem
        prismicData={data}
        handleRedeemNow={offer => {
          handleSaveProduct(user, offer, setLoading, lang, true)
        }}
        processing={loading}
        lang={lang}
        layoutData={layout}
        handleAffiliateOffer={handleAffiliateOffer}
        personalizedOfferPage={personalizedOfferPage}
      />
      <Varieties data={varieties} lang={lang} />
      <TrainYourWay data={app_stores_available} offersPage={true} />
      <Footer links={footer} copyright={copyright.text} offersPage />
    </>
  )
}

export default React.memo(Offers, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.data, nextProps.data) &&
    isEqual(prevProps.user, nextProps.user) &&
    isEqual(prevProps.layout, nextProps.layout)
  )
})
