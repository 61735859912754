import React from "react"
import {
  Button,
  convertCentsToEuro,
  formatIntervalUnit,
  CURRENCIES,
  isZeroDecimalCurrency,
} from "@lesmills/gatsby-theme-common"

const Subscription = ({
  offer,
  prismicData,
  handleRedeemNow,
  processing,
  layoutData,
}: {
  offer: object,
  prismicData: object,
  handleRedeemNow: () => void,
  processing: boolean,
  layoutData: object,
}) => {
  const { redeem_button = {}, normally = {} } = prismicData || {}
  const { product_price_point = {}, currency } = offer
  const normalPrice = convertCentsToEuro(
    product_price_point.price_in_cents,
    currency,
    2
  )
  const offerPrice = convertCentsToEuro(
    product_price_point.final_price_in_cents,
    currency,
    2
  )
  const unit = formatIntervalUnit(product_price_point, layoutData)
  const currencySymbol = CURRENCIES[currency]

  return (
    <div className="mx-20 mb-20">
      <p className="font-primary relative mr-100 w-full">
        <span className="text-5xl ls-x-1.21">{currencySymbol}</span>
        <span className="text-8xxl">{parseInt(offerPrice)}</span>
        <span className="absolute top-10">
          {!isZeroDecimalCurrency(currency) && (
            <span className="text-xxl">.{offerPrice.split(".")[1]}</span>
          )}
          <span className="text-xxl">/</span>
          <span className="text-xl">{unit}</span>
        </span>
      </p>

      <div className="min-h-44">
        {normalPrice !== offerPrice && (
          <p className="text-3xs md:text-base font-light font-base-light pb-20">
            <span>{normally.text} </span>
            {currencySymbol}
            <span className="relative price-tag">{normalPrice}</span>/
            <span>{unit}</span>
          </p>
        )}
      </div>
      <Button
        handleOnClick={handleRedeemNow}
        submitting={processing}
        disabled={processing}
        className="uppercase btn btn-primary w-335 pt-18 pb-18 mb-10 focus:outline-none text-black px-80"
      >
        {redeem_button.text}
      </Button>
    </div>
  )
}

export default Subscription
