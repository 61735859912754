import React from "react"
import isEqual from "lodash/isEqual"
import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import type { UserType } from "@lesmills/gatsby-theme-common"

import {
  renderSEO,
  PublicRoute,
  DATA_LAYER_SCRIPT,
} from "@lesmills/gatsby-theme-common"

import Offers from "../components/Offers"

type Props = {|
  pageContext: {
    lang: string,
    data: Object,
  },
  location: object,
  data: object,
  lang: string,
|}

const PersonalizedOfferPage = (props: Props) => {
  const { pageContext, location, data } = props || {}
  const scriptsPersonallizedOfferPage = [DATA_LAYER_SCRIPT]

  if (!data) return null

  const lang = pageContext.unPublishedLang || pageContext.lang

  const { prismicLayout = {}, prismicPersonalizedOfferPages = {} } = data
  const pageContent = prismicPersonalizedOfferPages.data || {}
  const layout = prismicLayout.data || {}

  const renderPersonalizedOfferPage = ({ user }: UserType) => (
    <>
      {renderSEO(
        pageContent,
        location.pathname,
        lang,
        scriptsPersonallizedOfferPage
      )}
      <Offers
        data={pageContent}
        lang={lang}
        user={user}
        layout={layout}
        personalizedOfferPage
      />
      )}
    </>
  )

  return (
    <PublicRoute
      component={renderPersonalizedOfferPage}
      {...props}
      lang={lang}
    />
  )
}

export const query = graphql`
  query personalizedOfferQuery($lang: String, $uid: String) {
    prismicLayout(lang: { eq: $lang }) {
      data {
        ...LayoutUnits
        best_value {
          raw
          text
        }
        les_mills_plus_logo {
          alt
          url
        }
      }
    }
    prismicPersonalizedOfferPages(lang: { eq: $lang }, uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicPersonalizedOfferPagesBodyVarieties {
            id
            items {
              number {
                raw
                text
              }
              label {
                raw
                text
              }
            }
            primary {
              subtitle {
                raw
                text
              }
              varieties_title {
                raw
                text
              }
            }
          }
          ... on PrismicPersonalizedOfferPagesBodyAppStoresAvailable {
            items {
              app_store_image {
                alt
                url
              }
              url {
                url
              }
            }
            primary {
              title {
                text
                raw
              }
              photo {
                alt
                url
              }
              subtitle {
                raw
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicPersonalizedOfferPagesBody1Navigation {
            items {
              navigation {
                raw
                text
              }
            }
          }
          ... on PrismicPersonalizedOfferPagesBody1Social {
            items {
              icon {
                text
              }
              link {
                url
              }
            }
          }
        }
        copyright {
          text
        }
        hero_highlight {
          raw
          text
        }
        hero_photo {
          alt
          url
          thumbnails {
            mobile {
              alt
              url
            }
          }
        }
        hero_video {
          raw
        }
        hero_title {
          raw
          text
        }
        lmod_fccoupon_err {
          text
        }
        lmod_gofferds_err {
          text
        }
        lmod_lafoffer_err {
          text
        }
        lmod_lpbc_err {
          text
        }
        lmod_ocnmatch_err {
          text
        }
        lmod_rpppoint_err {
          text
        }
        lmod_saoffer_err {
          text
        }
        normally {
          raw
          text
        }
        note_text {
          raw
          text
        }
        page_author {
          text
        }
        page_description {
          text
        }
        page_image {
          alt
          url
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        personalized_logo {
          alt
          url
        }
        redeem_button {
          text
        }
        redeem_description {
          text
          raw
        }
        redeem_information {
          text
          raw
        }
        redeem_note {
          text
          raw
        }
        redeem_title {
          text
          raw
        }
      }
    }
  }
`

export default withPreview(
  React.memo(PersonalizedOfferPage, (prevProps, nextProps) => {
    return isEqual(prevProps.data, nextProps.data)
  })
)
