import React, { useState, useEffect, useRef } from "react"
import { RichText } from "prismic-reactjs"

import type { AllLayoutsType } from "@lesmills/gatsby-theme-common"

import {
  htmlSerializerUpdateStyle,
  linkResolver,
  ProgressBar,
  getOfferById,
  getParamsFromURL,
  ROUTES,
  setLocalStorage,
  USER_COUNTRY,
  getProduct,
  SENTRY_ACTIONS,
  captureException,
  getSessionStorage,
  CURRENT_COUNTRY,
} from "@lesmills/gatsby-theme-common"

import Subscription from "./Subscription"
import type { OffersPageType } from "../../types/OffersPageType"

import { navigate } from "gatsby"
import { hasParamsInURL } from "@lesmills/gatsby-theme-common/src/utils/utilities"

type Props = {|
  prismicData: OffersPageType,
  handleRedeemNow: () => void,
  processing?: Boolean,
  lang: String,
  layoutData: AllLayoutsType,
  personalizedOfferPage?: boolean,
  handleAffiliateOffer: () => void,
|}

const Redeem = ({
  prismicData,
  handleRedeemNow,
  processing = false,
  lang,
  layoutData = {},
  personalizedOfferPage,
  handleAffiliateOffer = () => {},
}: Props) => {
  const [offer, setOffer] = useState([])
  const [loading, setLoading] = useState(0)
  const offerId = getParamsFromURL("offer_id")
  const affId = getParamsFromURL("aff_id")
  const transactionId = getParamsFromURL("transaction_id")
  const {
    redeem_title = {},
    redeem_description = {},
    redeem_information = {},
    redeem_description_with_affiliate_name = {},
    redeem_note = {},
  } = prismicData || {}
  const isUnmount = useRef(false)

  if (!prismicData) {
    return null
  }

  const setAffiliateOffer = data => {
    const errors = data.errors

    if (errors) {
      handleError(errors)

      return
    }

    //https://lesmillsinternational.atlassian.net/browse/AB2B-905
    let res = data.data.getAffiliateOffers
    res.sort((a, b) => a.sort_order - b.sort_order)

    const offers = res.map(item => ({
      offerId,
      transactionId,
      affiliateId: affId,
      currency: item.product_price_point && item.product_price_point.currency,
      ...item,
    }))

    const affiliateLogo = res[0].affiliate && res[0].affiliate.logo_url

    setLocalStorage(USER_COUNTRY, getSessionStorage(CURRENT_COUNTRY))
    setOffer(offers)

    handleAffiliateOffer(affiliateLogo)
  }

  const handleError = errors => {
    navigate(ROUTES(lang).NOT_FOUND)

    // Send error to Sentry
    if (errors.length > 0) {
      captureException({
        action: SENTRY_ACTIONS.GET_OFFER_BY_ID,
        requestVariables: {
          affId,
          offerId,
          transactionId,
        },
        ...errors[0],
      })
    }
  }

  useEffect(() => {
    if (!hasParamsInURL()) {
      // https://lesmillsinternational.atlassian.net/browse/LA-722
      // When customers try to access /offers, without the TUNE Paremeters Direct them to our Retail landing page
      navigate(ROUTES(lang).SALES_LANDING)
      return
    }

    getProduct(
      number => {
        if (!isUnmount.current) {
          setLoading(number)
        }
      },
      getOfferById,
      setAffiliateOffer,
      handleError,
      {
        offer_id: offerId,
        ...(!personalizedOfferPage && {
          affiliate_id: affId,
        }),
      }
    )

    // This is used to avoid memory leak when unmount
    return () => {
      isUnmount.current = true
    }
  }, [])

  const renderOffer = () => {
    if (loading < 100)
      return (
        <div className="mx-auto my-20 md:w-progress-bar w-full">
          <ProgressBar
            currentValue={loading}
            classNames={{ wrapper: " w-full" }}
          />
        </div>
      )

    return (
      <div className="flex flex-wrap justify-center">
        {/* https://lesmillsinternational.atlassian.net/browse/AB2B-905 */}
        {offer.map(item => (
          <Subscription
            key={item.offer_handle}
            handleRedeemNow={() =>
              handleRedeemNow({
                offerId,
                transactionId,
                affiliateId: affId,
                ...item,
              })
            }
            prismicData={prismicData}
            offer={item}
            processing={processing}
            layoutData={layoutData}
          />
        ))}
      </div>
    )
  }

  const renderDescription = () => {
    const affiliateName = offer.affiliate && offer.affiliate.name

    return affiliateName
      ? redeem_description_with_affiliate_name.raw
        ? redeem_description_with_affiliate_name.raw.map(item => {
            const text = item.text || ""

            return {
              ...item,
              text: text.replace("@affiliate_name", affiliateName),
            }
          })
        : []
      : redeem_description.raw
  }

  return (
    <section className="flex text-gray-800 flex-col items-center pt-50 pb-40 m-auto max-w-335 text-center md:m-auto md:max-w-600 lg:max-w-780 md:pt-100 md:pb-100">
      <h4 className="text-3xl font-base-black font-black pb-20 leading-6none">
        {redeem_title.text}
      </h4>
      <RichText
        render={renderDescription()}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "leading-4relaxed md:leading-2normal md:ls-x-0.45 text-base md:text-2lg font-light font-base-light pb-15"
        )}
      />
      <RichText
        render={redeem_information.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "leading-4relaxed md:leading-2normal md:ls-x-0.45 text-base md:text-2lg font-light font-base-light"
        )}
      />
      {renderOffer()}
      <RichText
        render={redeem_note.raw}
        linkResolver={linkResolver}
        htmlSerializer={htmlSerializerUpdateStyle(
          "",
          "text-xs md:text-3xs font-light font-base-light"
        )}
      />
    </section>
  )
}

export default React.memo(Redeem, (prevProps, nextProps) => {
  return prevProps.processing !== nextProps.processing
})
